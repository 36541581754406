import { z } from 'zod';

export const schema = z.object({
  meeting_url: z.string(),
  bot_name: z.string(),
  join_at: z
    .date()
    .min(new Date(Date.now() - 5 * 60 * 1_000))
    .optional()
    .nullable(),
});
