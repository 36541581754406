import { useActiveOrg } from '@propelauth/react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Bots } from './screens/Bots';
import { Calendars } from './screens/Calendars';
import { CustomWords } from './screens/CustomWords';
import { Dashboard } from './screens/Dashboard';
import { Settings } from './screens/Settings';
import { Transcripts } from './screens/Transcripts';
import { SelectOrg } from './SelectOrg';

export function App() {
  const org = useActiveOrg();
  if (!org) {
    return <SelectOrg />;
  }

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/bots/*" element={<Bots />} />
        <Route path="/calendars/*" element={<Calendars />} />
        <Route path="/custom-words/*" element={<CustomWords />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/transcripts/*" element={<Transcripts />} />
      </Routes>
    </Layout>
  );
}
