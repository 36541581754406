import { Loader, rem, Select } from '@mantine/core';
import { useActiveOrg, useOrgHelper } from '@propelauth/react';
import { IconBuilding } from '@tabler/icons-react';

export function SelectOrg() {
  const { orgHelper, loading } = useOrgHelper();
  if (loading || !orgHelper) {
    return <Loader />;
  }
  const organizations = orgHelper.getOrgs();
  organizations.sort((a, b) => a.orgName.localeCompare(b.orgName));
  const org = useActiveOrg();
  return (
    <Select
      m="sm"
      data={organizations.map(({ orgId, orgName }) => ({ value: orgId, label: orgName }))}
      value={org?.orgId}
      leftSection={<IconBuilding style={{ width: rem(16), height: rem(16) }} />}
      onChange={(orgId) => {
        if (orgId) {
          localStorage.setItem('ActiveOrg', orgId);
          document.location.reload();
        }
      }}
      placeholder="Select org"
    />
  );
}
