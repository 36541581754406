import { Anchor, Button, Flex, Table, Tooltip } from '@mantine/core';
import { FormErrors } from '@mantine/form/lib/types';
import { IconEye, IconFileText, IconTrash } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Bot, useDeleteV1BotsId, useGetV1Transcripts } from '../../api';

interface Props {
  bot: Bot;
  onDeleteSuccess: (data: unknown, variables: { id: string }, context: unknown) => unknown;
  onDeleteError?: (error: FormErrors, variables: { id: string }, context: unknown) => unknown;
}

export function Row({ bot, onDeleteSuccess, onDeleteError }: Readonly<Props>) {
  const transcripts = useGetV1Transcripts({ bot_id: bot.id });

  const deleteBot = useDeleteV1BotsId({
    mutation: { onSuccess: onDeleteSuccess, onError: onDeleteError },
  });

  const onDelete = useCallback(() => deleteBot.mutate({ id: bot.id }), [deleteBot, bot]);

  const transcript = transcripts?.data?.body.items[0];

  return (
    <Table.Tr>
      <Table.Td>{bot.bot_name}</Table.Td>
      <Table.Td>{bot.status}</Table.Td>

      <Table.Td>
        {transcript ? (
          <Anchor component={Link} to={`/transcripts/${transcript.id}`}>
            <IconFileText />
          </Anchor>
        ) : (
          ''
        )}
      </Table.Td>
      <Table.Td>{bot.join_at ? dayjs(bot.join_at).format('D MMM, YYYY HH:mm:ss') : '-'}</Table.Td>
      <Table.Td>
        <Flex align="center">
          <Tooltip label="View Bot">
            <Anchor component={Link} to={bot.id}>
              <Button variant="outline" size="xs">
                <IconEye />
              </Button>
            </Anchor>
          </Tooltip>
          &nbsp;
          <Tooltip label="Delete Bot">
            <Button onClick={onDelete} variant="outline" color="red" size="xs">
              <IconTrash />
            </Button>
          </Tooltip>
        </Flex>
      </Table.Td>
    </Table.Tr>
  );
}
