import { Button, Checkbox, Table, Tooltip } from '@mantine/core';
import { FormErrors } from '@mantine/form/lib/types';
import { IconTrash } from '@tabler/icons-react';
import { useCallback } from 'react';
import { CustomWord, useDeleteV1CustomWordsId, usePutV1CustomWordsId } from '../../api';
import { ApiResponse } from '../../api/mutator/custom-instance';

interface Props {
  customWord: CustomWord;
  onDeleteSuccess: (
    data: ApiResponse<CustomWord>,
    variables: { id: string },
    context: unknown,
  ) => unknown;
  onDeleteError: (error: FormErrors, variables: { id: string }, context: unknown) => unknown;
  onUpdateSuccess: (
    data: ApiResponse<CustomWord>,
    variables: { id: string },
    context: unknown,
  ) => unknown;
  onUpdateError: (error: FormErrors, variables: { id: string }, context: unknown) => unknown;
}

export function Row({
  customWord,
  onDeleteError,
  onDeleteSuccess,
  onUpdateSuccess,
  onUpdateError,
}: Readonly<Props>) {
  const deleteCustomWord = useDeleteV1CustomWordsId({
    mutation: { onSuccess: onDeleteSuccess, onError: onDeleteError },
  });
  const onDelete = useCallback(
    () => deleteCustomWord.mutate({ id: customWord.id }),
    [deleteCustomWord, customWord],
  );

  const updateCustomWord = usePutV1CustomWordsId({
    mutation: { onSuccess: onUpdateSuccess, onError: onUpdateError },
  });
  return (
    <Table.Tr>
      <Table.Td>{customWord.text}</Table.Td>
      <Table.Td>
        <Checkbox
          checked={customWord.enabled}
          onChange={(event) =>
            updateCustomWord.mutate({
              id: customWord.id,
              data: { text: customWord.text, enabled: event.currentTarget.checked },
            })
          }
        />
      </Table.Td>
      <Table.Td>
        <Tooltip label="Delete Custom Word">
          <Button onClick={onDelete} variant="outline" color="red" size="xs">
            <IconTrash />
          </Button>
        </Tooltip>
      </Table.Td>
    </Table.Tr>
  );
}
