import { Divider, NavLink as MantineNavLink } from '@mantine/core';
import {
  IconCalendar,
  IconHelp,
  IconHome,
  IconMessages,
  IconNotification,
  IconQuote,
  IconSettings,
} from '@tabler/icons-react';
import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { useGetV1WebhooksAppPortalUrl } from '../../api';

const links: (ParentLinkProps | MainLinkProps)[] = [
  {
    icon: <IconHome size={16} />,
    label: 'Dashboard',
    to: '/',
  },
  {
    icon: <IconMessages size={16} />,
    label: 'Transcripts',
    to: '/transcripts',
  },
  {
    icon: <IconMessages size={16} />,
    label: 'Bots',
    to: '/bots',
  },
  {
    icon: <IconQuote size={16} />,
    label: 'Custom Words',
    to: '/custom-words',
  },
  {
    icon: <IconCalendar size={16} />,
    label: 'Calendars',
    to: '/calendars',
  },
  {
    icon: <IconSettings size={16} />,
    label: 'Settings',
    to: '/settings',
  },
];

const externalLinks: ExternalLinkProps[] = [
  {
    icon: <IconHelp size={16} />,
    label: 'Docs',
    href: 'https://skriver.readme.io',
  },
];

function isParent(link: ParentLinkProps | MainLinkProps): link is ParentLinkProps {
  return 'links' in link;
}

interface ParentLinkProps {
  icon: ReactNode;
  label: string;
  links: MainLinkProps[];
}

interface MainLinkProps {
  icon: ReactNode;
  label: string;
  to: string;
}

interface ExternalLinkProps {
  icon: ReactNode;
  label: string;
  href: string;
}

interface Props {
  toggleSidebar: () => void;
}

export function MainLinks({ toggleSidebar }: Readonly<Props>) {
  const appLink = useGetV1WebhooksAppPortalUrl();

  return (
    <>
      {links.map((link) =>
        isParent(link) ? (
          <MantineNavLink
            key={link.label}
            label={link.label}
            leftSection={link.icon}
            childrenOffset={28}
          >
            {link.links?.map((childLink) => (
              <MantineNavLink
                key={childLink.label}
                label={childLink.label}
                component={NavLink}
                to={childLink.to}
                leftSection={childLink.icon}
                onClick={toggleSidebar}
              />
            ))}
          </MantineNavLink>
        ) : (
          <MantineNavLink
            key={link.label}
            label={link.label}
            component={NavLink}
            to={link.to}
            leftSection={link.icon}
            onClick={toggleSidebar}
          />
        ),
      )}
      <Divider my="md" />
      {externalLinks.map((link) => (
        <MantineNavLink
          key={link.label}
          label={link.label}
          href={link.href}
          leftSection={link.icon}
          target="_blank"
          onClick={toggleSidebar}
        />
      ))}
      {appLink.data?.body && (
        <MantineNavLink
          label="Webhooks"
          href={appLink.data.body.url}
          leftSection={<IconNotification size={16} />}
          target="_blank"
          onClick={toggleSidebar}
        />
      )}
    </>
  );
}
