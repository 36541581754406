import { Button, Divider, Flex, LoadingOverlay, Modal, Table, Title, Tooltip } from '@mantine/core';
import { FormErrors } from '@mantine/form/lib/types';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconPlus } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useCallback } from 'react';
import { PaginatedVecForTranscript, Transcript, useGetV1Transcripts } from '../../api';
import { ApiResponse } from '../../api/mutator/custom-instance';
import { Form } from './Form';
import { Row } from './Row';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.guess(); // America/Chicago

export function List() {
  const [opened, { open, close }] = useDisclosure(false);

  const queryClient = useQueryClient();
  const transcripts = useGetV1Transcripts({ per_page: 100 });

  const onCreateSuccess = useCallback(
    (apiResponse: ApiResponse<Transcript>) => {
      queryClient.setQueryData(
        transcripts.queryKey,
        (oldData: ApiResponse<PaginatedVecForTranscript>) => {
          return {
            ...oldData,
            body: { ...oldData.body, items: [...oldData.body.items, apiResponse.body] },
          };
        },
      );
      notifications.show({
        title: `Success`,
        message: 'Successfully created transcript',
        color: 'green',
        withCloseButton: true,
      });
      close();
    },
    [transcripts.queryKey],
  );

  const onCreateError = useCallback((error: FormErrors) => {
    console.error(error);
  }, []);

  const onDeleteSuccess = useCallback(
    (_data: unknown, variables: { id: string }) => {
      queryClient.setQueryData(
        transcripts.queryKey,
        ({ body, headers }: ApiResponse<PaginatedVecForTranscript>) => ({
          headers,
          body: { ...body, items: body.items.filter((i) => i.id !== variables.id) },
        }),
      );
    },
    [transcripts.queryKey],
  );

  const onDeleteError = useCallback((error: FormErrors) => {
    console.error(error);
  }, []);

  if (transcripts.isLoading) return <LoadingOverlay visible />;
  if (transcripts.isError) return <h1>{transcripts.error?.message}</h1>;
  if (!transcripts.isSuccess) return <LoadingOverlay visible />;

  return (
    <>
      <Flex justify="space-between">
        <Title>Transcripts</Title>
        <Tooltip label="Create Transcript">
          <Button onClick={open}>
            <IconPlus />
          </Button>
        </Tooltip>
      </Flex>
      <Divider mb="md" />

      <Modal opened={opened} onClose={close} title="Create Transcript">
        <Form onSuccess={onCreateSuccess} onError={onCreateError} />
      </Modal>

      <Table.ScrollContainer minWidth={800}>
        <Table verticalSpacing="xs">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Status</Table.Th>
              <Table.Th>Level</Table.Th>
              <Table.Th>Duration</Table.Th>
              <Table.Th>Bot</Table.Th>
              <Table.Th>Date</Table.Th>
              <Table.Th></Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {transcripts.data.body.items.map((transcript) => (
              <Row
                key={transcript.id}
                transcript={transcript}
                onDeleteSuccess={onDeleteSuccess}
                onDeleteError={onDeleteError}
              />
            ))}
          </Table.Tbody>
        </Table>
      </Table.ScrollContainer>
    </>
  );
}
