import { Button, Select, TextInput } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { useForm, zodResolver } from '@mantine/form';
import { FormErrors } from '@mantine/form/lib/types';
import { useCallback, useMemo, useState } from 'react';
import {
  RemoteTranscriptRequest,
  Transcript,
  TranscriptionLevel,
  usePostV1TranscriptsRemote,
} from '../../api';
import { ApiResponse } from '../../api/mutator/custom-instance';
import { schema } from './schema';

interface Props {
  onSuccess: (
    data: ApiResponse<Transcript>,
    variables: { data: RemoteTranscriptRequest },
    context: unknown,
  ) => unknown;
  onError: (
    error: FormErrors,
    variables: { data: RemoteTranscriptRequest },
    context: unknown,
  ) => unknown;
}

export function Form({ onSuccess, onError }: Readonly<Props>) {
  const [loading, setLoading] = useState(false);
  const botMutation = usePostV1TranscriptsRemote({
    mutation: { onSuccess, onError },
  });

  const handleSubmit = useCallback(
    (values: RemoteTranscriptRequest) => {
      setLoading(true);
      botMutation.mutate({
        data: {
          level: values.level,
          media_url: values.media_url,
          metadata: values.metadata,
          recorded_at: values.recorded_at,
        },
      });
    },
    [botMutation.mutate],
  );

  const defaultValues = useMemo<RemoteTranscriptRequest>(
    () => ({
      level: TranscriptionLevel.basic,
      media_url: '',
      metadata: null,
      recorded_at: null,
    }),
    [],
  );

  const form = useForm<RemoteTranscriptRequest>({
    initialValues: defaultValues,
    validate: zodResolver(schema),
    validateInputOnBlur: true,
  });

  return (
    <form onSubmit={form.onSubmit(handleSubmit, console.error)}>
      <TextInput
        mb="md"
        autoFocus
        label="Media URL"
        type="url"
        placeholder="https://example.com/audio.wav"
        {...form.getInputProps('media_url')}
        required
      />
      <Select
        mb="md"
        label="level"
        data={Object.entries(TranscriptionLevel).map(([value, label]) => ({ value, label }))}
        {...form.getInputProps('level')}
        required
      />
      <DateTimePicker
        mb="md"
        label="recorded_at"
        placeholder="Recorded At"
        minDate={new Date()}
        {...form.getInputProps('recorded_at')}
      />
      <Button loading={loading} fullWidth type="submit">
        Submit
      </Button>
    </form>
  );
}
