import { Group } from '@mantine/core';
import React from 'react';

export function Logo() {
  return (
    <Group>
      <svg
        style={{ width: '2rem', height: '2rem' }}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        x="0px"
        y="0px"
        viewBox="-1.5 -1.5 35 35"
        enableBackground="new 0 0 32 32"
        xmlSpace="preserve"
      >
        <path
          fill="url(#Gradient)"
          d="M16,31.5c8.56,0,15.5-6.94,15.5-15.5c0-8.318-6.557-15.085-14.781-15.464V0.5h-2v0.065C6.76,1.217,0.5,7.872,0.5,16   C0.5,24.56,7.44,31.5,16,31.5z M15.719,8.485l6.332,11.844H9.387L15.719,8.485z M14.719,2.565v3.549l-0.764,1.428L7.623,19.386   l-1.574,2.943h3.337h12.665h3.337l-1.573-2.943L17.483,7.542l-0.764-1.429V2.536C23.828,2.914,29.5,8.799,29.5,16   c0,7.444-6.056,13.5-13.5,13.5C8.556,29.5,2.5,23.444,2.5,16C2.5,8.988,7.874,3.213,14.719,2.565z"
        />

        <defs>
          <linearGradient id="Gradient">
            <stop offset="0%" stopColor="#0F7" />
            <stop offset="100%" stopColor="#09F" />
          </linearGradient>
        </defs>
      </svg>
      Skriver
    </Group>
  );
}
