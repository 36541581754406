import { Button, Checkbox, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { FormErrors } from '@mantine/form/lib/types';
import { useCallback, useMemo } from 'react';
import { CreateCustomWordRequest, CustomWord, usePostV1CustomWords } from '../../api';
import { ApiResponse } from '../../api/mutator/custom-instance';
import { schema } from './schema';

interface Props {
  onSuccess: (
    data: ApiResponse<CustomWord>,
    variables: { data: CreateCustomWordRequest },
    context: unknown,
  ) => unknown;
  onError: (
    error: FormErrors,
    variables: { data: CreateCustomWordRequest },
    context: unknown,
  ) => unknown;
}

export function Form({ onSuccess, onError }: Readonly<Props>) {
  const customWordMutation = usePostV1CustomWords({
    mutation: { onSuccess, onError },
  });

  const handleSubmit = useCallback(
    (values: CreateCustomWordRequest) =>
      customWordMutation.mutate({ data: { text: values.text, enabled: values.enabled } }),
    [customWordMutation.mutate],
  );

  const defaultValues = useMemo<CreateCustomWordRequest>(() => ({ text: '', enabled: true }), []);

  const form = useForm<CreateCustomWordRequest>({
    initialValues: defaultValues,
    validate: zodResolver(schema),
    validateInputOnBlur: true,
  });

  return (
    <form onSubmit={form.onSubmit(handleSubmit, console.error)}>
      <TextInput
        mb="md"
        autoFocus
        placeholder="Text or Phrase"
        required
        {...form.getInputProps('text')}
      />
      <Checkbox
        mb="md"
        label="Enabled"
        key={form.key('enabled')}
        {...form.getInputProps('enabled', { type: 'checkbox' })}
      />

      <Button fullWidth type="submit">
        Submit
      </Button>
    </form>
  );
}
