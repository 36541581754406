import { LineChart } from '@mantine/charts';
import {
  Center,
  Divider,
  Group,
  LoadingOverlay,
  Paper,
  rem,
  RingProgress,
  SimpleGrid,
  Text,
} from '@mantine/core';
import { IconHourglass, IconReportMoney, IconTextScan2 } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useGetV1Me, useGetV1UsageTranscripts } from '../../api';

const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
const timeFormatter = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const seconds = Math.floor((minutes % 60) % 60);
  return `${hours}:${Math.floor(minutes % 60)}:${seconds}`;
};

export function Dashboard() {
  const meQuery = useGetV1Me();
  const usageQuery = useGetV1UsageTranscripts();

  if (meQuery.isLoading || usageQuery.isLoading) {
    return <LoadingOverlay visible />;
  }
  if (!meQuery.isSuccess || !usageQuery.isSuccess) {
    return <LoadingOverlay visible />;
  }

  const formattedDate = usageQuery.data.body.map((item) => ({
    ...item,
    date: dayjs(item.time_window).format('D MMM'),
  }));

  const stats = [
    {
      label: 'Transcripts',
      stats: meQuery.data.body.count,
      progress: meQuery.data.body.count / 10,
      color: 'teal',
      icon: IconTextScan2,
      data: formattedDate.map((item) => ({
        date: item.date,
        Basic: item.basic_count,
        Premium: item.premium_count,
      })),
    },
    {
      label: 'Usage',
      stats: timeFormatter(meQuery.data.body.usage),
      progress: meQuery.data.body.usage / 60,
      color: 'blue',
      icon: IconHourglass,
      valueFormatter: (minutes: number) => timeFormatter(minutes),
      data: formattedDate.map((item) => ({
        date: item.date,
        Basic: item.basic_minutes,
        Premium: item.premium_minutes,
      })),
    },
    {
      label: 'Cost',
      stats: currencyFormatter.format(meQuery.data.body.cost),
      progress: meQuery.data.body.cost / 3,
      color: 'purple',
      icon: IconReportMoney,
      valueFormatter: (value: number) => currencyFormatter.format(value),
      data: formattedDate.map((item) => ({
        date: item.date,
        Basic: item.basic_cost,
        Premium: item.premium_cost,
      })),
    },
  ];

  return (
    <SimpleGrid cols={{ base: 1, sm: 3 }}>
      {stats.map((stat) => {
        const Icon = stat.icon;
        return (
          <Paper withBorder radius="md" p="xs" key={stat.label}>
            <Group>
              <RingProgress
                size={80}
                roundCaps
                thickness={8}
                sections={[{ value: stat.progress, color: stat.color }]}
                label={
                  <Center>
                    <Icon style={{ width: rem(20), height: rem(20) }} stroke={1.5} />
                  </Center>
                }
              />

              <div>
                <Text c="dimmed" size="xs" tt="uppercase" fw={700}>
                  {stat.label}
                </Text>
                <Text fw={700} size="xl">
                  {stat.stats}
                </Text>
              </div>
            </Group>
            <Divider mb="md" />
            <LineChart
              h={300}
              data={stat.data}
              dataKey="date"
              withLegend
              legendProps={{ verticalAlign: 'bottom', height: 50 }}
              valueFormatter={stat.valueFormatter}
              series={[
                { name: 'Basic', color: 'green.6' },
                { name: 'Premium', color: 'blue.6' },
              ]}
              curveType="linear"
            />
          </Paper>
        );
      })}
    </SimpleGrid>
  );
}
