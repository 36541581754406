import { Avatar, LoadingOverlay, NavLink } from '@mantine/core';
import { useActiveOrg, useAuthInfo } from '@propelauth/react';
import React from 'react';

export function User() {
  const auth = useAuthInfo();
  const org = useActiveOrg();

  if (auth.loading) {
    return <LoadingOverlay visible />;
  }

  if (!auth.user) {
    return <div>User not found</div>;
  }

  return (
    <NavLink
      label={`${auth.user.firstName} ${auth.user.lastName}`}
      description={auth.user.email}
      leftSection={<Avatar src={auth.user.pictureUrl} radius="xl" />}
      href={`${process.env.AUTH_URL}/account/settings/${org?.orgId}`}
    />
  );
}
