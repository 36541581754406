import { Button, Divider, Title } from '@mantine/core';
import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { usePostV1GoogleCalendarCodes, usePostV1MicrosoftCalendarCodes } from '../../api';
import { List } from './List';

export function Calendars() {
  const createGoogleCalendarCodeRequest = usePostV1GoogleCalendarCodes({
    mutation: {
      onSuccess: console.info,
    },
  });
  const createMicrosoftCalendarCodeRequest = usePostV1MicrosoftCalendarCodes({
    mutation: {
      onSuccess: console.info,
    },
  });

  useEffect(() => {
    if (createGoogleCalendarCodeRequest.isSuccess) {
      location.href = createGoogleCalendarCodeRequest.data.body.oauth_url;
    }
    if (createMicrosoftCalendarCodeRequest.isSuccess) {
      location.href = createMicrosoftCalendarCodeRequest.data.body.oauth_url;
    }
  }, [
    createGoogleCalendarCodeRequest.isSuccess,
    createGoogleCalendarCodeRequest.data?.body,
    createMicrosoftCalendarCodeRequest.isSuccess,
    createMicrosoftCalendarCodeRequest.data?.body,
  ]);

  return (
    <>
      <Title>Calendars</Title>
      <Divider mb="sm" />
      <Button.Group ta="center">
        <Button
          variant="outline"
          onClick={() => {
            createGoogleCalendarCodeRequest.mutate();
          }}
        >
          Connect Google Calendar
        </Button>
        <Button
          variant="outline"
          onClick={() => {
            createMicrosoftCalendarCodeRequest.mutate();
          }}
        >
          Connect Outlook Calendar
        </Button>
      </Button.Group>
      <Divider my="sm" />
      <Routes>
        <Route path="" element={<List />} />
      </Routes>
    </>
  );
}
