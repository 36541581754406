import { notifications } from '@mantine/notifications';
import { useActiveOrg, useAuthInfo } from '@propelauth/react';
import Axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponseHeaders,
  isAxiosError,
  isCancel,
  RawAxiosResponseHeaders,
} from 'axios';

const { API_URL } = process.env;

if (API_URL === undefined) {
  throw new Error('API_URL not defined');
}

export const AXIOS_INSTANCE = Axios.create({ baseURL: API_URL });

export const useCustomInstance = <TBody>(): ((
  config: AxiosRequestConfig,
) => Promise<{ body: TBody; headers: RawAxiosResponseHeaders | AxiosResponseHeaders }>) => {
  const auth = useAuthInfo();
  const org = useActiveOrg();

  const authorization =
    !auth.loading && auth.accessToken ? `Bearer ${auth.accessToken}` : undefined;

  return (config: AxiosRequestConfig) => {
    const source = Axios.CancelToken.source();
    const promise = AXIOS_INSTANCE<TBody>({
      ...config,
      headers: {
        ...config.headers,
        Authorization: authorization,
        'X-Org-Id': org?.orgId,
      },
      cancelToken: source.token,
    })
      .then(({ data, headers }) => ({ body: data, headers }))
      .catch((error) => {
        if (!isCancel(error) && isAxiosError(error) && error.response?.status !== 401) {
          notifications.show({
            title: `Error ${error.response?.data.error_id}`,
            message: error.response?.data.error ?? error.message,
            color: 'red',
            autoClose: true,
            withCloseButton: true,
          });
        }
        return Promise.reject(error);
      });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    promise.cancel = () => {
      source.cancel('Query was cancelled by React Query');
    };

    return promise;
  };
};

export interface ApiResponse<TBody> {
  body: TBody;
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders;
}

export default useCustomInstance;

export type ErrorType<Error> = AxiosError<Error>;
