/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Skriver
 * Skriver is your trusted companion for transcribing audio into text in a machine-friendly format.

# Features

- Transcribe audio files into text
- Join meetings and transcribe them
- Connect to calendars to join and transcribe meetings automatically

# Quick Start

## Step 1: Create a Skriver Account

Head to [auth.skriver.app](https://auth.skriver.app/signup) and register for a free account. You
will be redirected to [dash.skriver.app](https://dash.skriver.app).

## Step 2: Create your API Keys

Once logged int, click on your username and then "Organization API Keys".

> 🚧 Store the keys somewhere safe.
>
> Note that Skriver will make your API key available only once. Hence make sure to immediately copy
> and save the key in a safe and secure location.
>
> Your key is used to authenticate into our APIs and is hence highly sensitive. Please make sure to
> keep the keys in a secure environment. In the event of a compromise, you can immediately revoke
> key
> access from our dashboard and generate a new key.

## Step 3: Transcribe your first audio

You are now ready to query our APIs.

```curl
curl --request POST \
    --url 'https://api.skriver.app/v1/transcripts/remote' \
    --header 'Content-Type: application/json' \
    --header 'X-Api-Key: <API_KEY>' \
    --data '{
        "media_url": "https://assets.skriver.app/examples/interview_speech-analytics.wav",
        "level": "basic",
        "recorded_at": "2023-12-24T10:00:00.000Z",
        "metadata": { "foo": "bar" }
    }'
```

This endpoint will return a response like the following:

```json
{
    "id": "018e8550-9db8-764c-8c11-b8cd85727d9e",
    "level": "basic",
    "status": "queued",
    "monologues": null,
    "duration_in_seconds": null,
    "metadata": {
        "foo": "bar"
    },
    "speakers": null,
    "recorded_at": "2023-12-24T10:00:00Z",
    "created_at": "2024-03-28T13:47:17.838803Z"
}
```

Use the `id` to monitor the status of the transcription by calling the `GET` endpoint

```curl
curl --request GET \
     --url 'https://api.skriver.app/transcripts/018c00cf-076c-7c01-bcc1-dad2e8074bc8' \
     --header 'X-Api-Key: <API_KEY>'
```

You will get a response that contains the transcription text

```json
{
    "id": "018e8550-9db8-764c-8c11-b8cd85727d9e",
    "level": "basic",
    "status": "completed",
    "monologues": [
        {
            "start": 3.1999998,
            "end": 29.855,
            "speaker": 0,
            "text": "Some transcribed text here"
        }
    ],
    "duration_in_seconds": 33958.688,
    "metadata": {
        "foo": "bar"
    },
    "speakers": [
        "0"
    ],
    "recorded_at": "2023-12-24T10:00:00Z",
    "created_at": "2024-03-28T13:47:17.838803Z"
}
```

Congratulations! You have now converted an audio file into text.

## Step 4: Schedule a bot to join your meeting

You can now schedule a bot to join your meeting and transcribe it. Using the API key, you can create
a "bot"

```curl
curl --request GET \
    --url 'https://api.skriver.app/v1/bots/' \
    --header 'Content-Type: application/json' \
    --header 'X-Api-Key: <API_KEY>' \
    --data '{
        "bot_name": "C3-P0",
        "meeting_url": "https://meet.google.com/abc-defg-hij",
        "join_at": "2024-03-28T10:00:00Z",
        "metadata": { "foo": "bar" }
    }'
```

 * OpenAPI spec version: 0.3.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useCallback } from 'react';
import type {
  GetV1GoogleCalendarEventsParams,
  GoogleCalendarEvent,
  PaginatedVecForGoogleCalendarEvent,
  UpdateGoogleCalendarEventRequest,
} from '../../models';
import { useCustomInstance } from '../../mutator/custom-instance';
import type { ErrorType } from '../../mutator/custom-instance';

/**
 * @summary List all google calendar events
 */
export const useGetV1GoogleCalendarEventsHook = () => {
  const getV1GoogleCalendarEvents = useCustomInstance<PaginatedVecForGoogleCalendarEvent>();

  return useCallback(
    (params?: GetV1GoogleCalendarEventsParams, signal?: AbortSignal) => {
      return getV1GoogleCalendarEvents({
        url: `/v1/google-calendar-events/`,
        method: 'GET',
        params,
        signal,
      });
    },
    [getV1GoogleCalendarEvents],
  );
};

export const getGetV1GoogleCalendarEventsQueryKey = (params?: GetV1GoogleCalendarEventsParams) => {
  return [`/v1/google-calendar-events/`, ...(params ? [params] : [])] as const;
};

export const useGetV1GoogleCalendarEventsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetV1GoogleCalendarEventsHook>>>,
  TError = ErrorType<unknown>,
>(
  params?: GetV1GoogleCalendarEventsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1GoogleCalendarEventsHook>>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetV1GoogleCalendarEventsQueryKey(params);

  const getV1GoogleCalendarEvents = useGetV1GoogleCalendarEventsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetV1GoogleCalendarEventsHook>>>
  > = ({ signal }) => getV1GoogleCalendarEvents(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetV1GoogleCalendarEventsHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetV1GoogleCalendarEventsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetV1GoogleCalendarEventsHook>>>
>;
export type GetV1GoogleCalendarEventsQueryError = ErrorType<unknown>;

export function useGetV1GoogleCalendarEvents<
  TData = Awaited<ReturnType<ReturnType<typeof useGetV1GoogleCalendarEventsHook>>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | GetV1GoogleCalendarEventsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1GoogleCalendarEventsHook>>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetV1GoogleCalendarEventsHook>>>,
          TError,
          TData
        >,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetV1GoogleCalendarEvents<
  TData = Awaited<ReturnType<ReturnType<typeof useGetV1GoogleCalendarEventsHook>>>,
  TError = ErrorType<unknown>,
>(
  params?: GetV1GoogleCalendarEventsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1GoogleCalendarEventsHook>>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetV1GoogleCalendarEventsHook>>>,
          TError,
          TData
        >,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetV1GoogleCalendarEvents<
  TData = Awaited<ReturnType<ReturnType<typeof useGetV1GoogleCalendarEventsHook>>>,
  TError = ErrorType<unknown>,
>(
  params?: GetV1GoogleCalendarEventsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1GoogleCalendarEventsHook>>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary List all google calendar events
 */

export function useGetV1GoogleCalendarEvents<
  TData = Awaited<ReturnType<ReturnType<typeof useGetV1GoogleCalendarEventsHook>>>,
  TError = ErrorType<unknown>,
>(
  params?: GetV1GoogleCalendarEventsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1GoogleCalendarEventsHook>>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useGetV1GoogleCalendarEventsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get a single google calendar event
 */
export const useGetV1GoogleCalendarEventsIdHook = () => {
  const getV1GoogleCalendarEventsId = useCustomInstance<GoogleCalendarEvent>();

  return useCallback(
    (id: string, signal?: AbortSignal) => {
      return getV1GoogleCalendarEventsId({
        url: `/v1/google-calendar-events/${id}`,
        method: 'GET',
        signal,
      });
    },
    [getV1GoogleCalendarEventsId],
  );
};

export const getGetV1GoogleCalendarEventsIdQueryKey = (id: string) => {
  return [`/v1/google-calendar-events/${id}`] as const;
};

export const useGetV1GoogleCalendarEventsIdQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetV1GoogleCalendarEventsIdHook>>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1GoogleCalendarEventsIdHook>>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetV1GoogleCalendarEventsIdQueryKey(id);

  const getV1GoogleCalendarEventsId = useGetV1GoogleCalendarEventsIdHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetV1GoogleCalendarEventsIdHook>>>
  > = ({ signal }) => getV1GoogleCalendarEventsId(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetV1GoogleCalendarEventsIdHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetV1GoogleCalendarEventsIdQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetV1GoogleCalendarEventsIdHook>>>
>;
export type GetV1GoogleCalendarEventsIdQueryError = ErrorType<unknown>;

export function useGetV1GoogleCalendarEventsId<
  TData = Awaited<ReturnType<ReturnType<typeof useGetV1GoogleCalendarEventsIdHook>>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1GoogleCalendarEventsIdHook>>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetV1GoogleCalendarEventsIdHook>>>,
          TError,
          TData
        >,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetV1GoogleCalendarEventsId<
  TData = Awaited<ReturnType<ReturnType<typeof useGetV1GoogleCalendarEventsIdHook>>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1GoogleCalendarEventsIdHook>>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetV1GoogleCalendarEventsIdHook>>>,
          TError,
          TData
        >,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetV1GoogleCalendarEventsId<
  TData = Awaited<ReturnType<ReturnType<typeof useGetV1GoogleCalendarEventsIdHook>>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1GoogleCalendarEventsIdHook>>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get a single google calendar event
 */

export function useGetV1GoogleCalendarEventsId<
  TData = Awaited<ReturnType<ReturnType<typeof useGetV1GoogleCalendarEventsIdHook>>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1GoogleCalendarEventsIdHook>>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useGetV1GoogleCalendarEventsIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update a google calendar event
 */
export const usePutV1GoogleCalendarEventsIdHook = () => {
  const putV1GoogleCalendarEventsId = useCustomInstance<GoogleCalendarEvent>();

  return useCallback(
    (id: string, updateGoogleCalendarEventRequest: UpdateGoogleCalendarEventRequest) => {
      return putV1GoogleCalendarEventsId({
        url: `/v1/google-calendar-events/${id}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: updateGoogleCalendarEventRequest,
      });
    },
    [putV1GoogleCalendarEventsId],
  );
};

export const usePutV1GoogleCalendarEventsIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutV1GoogleCalendarEventsIdHook>>>,
    TError,
    { id: string; data: UpdateGoogleCalendarEventRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePutV1GoogleCalendarEventsIdHook>>>,
  TError,
  { id: string; data: UpdateGoogleCalendarEventRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const putV1GoogleCalendarEventsId = usePutV1GoogleCalendarEventsIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePutV1GoogleCalendarEventsIdHook>>>,
    { id: string; data: UpdateGoogleCalendarEventRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return putV1GoogleCalendarEventsId(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutV1GoogleCalendarEventsIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePutV1GoogleCalendarEventsIdHook>>>
>;
export type PutV1GoogleCalendarEventsIdMutationBody = UpdateGoogleCalendarEventRequest;
export type PutV1GoogleCalendarEventsIdMutationError = ErrorType<unknown>;

/**
 * @summary Update a google calendar event
 */
export const usePutV1GoogleCalendarEventsId = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutV1GoogleCalendarEventsIdHook>>>,
    TError,
    { id: string; data: UpdateGoogleCalendarEventRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof usePutV1GoogleCalendarEventsIdHook>>>,
  TError,
  { id: string; data: UpdateGoogleCalendarEventRequest },
  TContext
> => {
  const mutationOptions = usePutV1GoogleCalendarEventsIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Delete a google calendar event
 */
export const useDeleteV1GoogleCalendarEventsIdHook = () => {
  const deleteV1GoogleCalendarEventsId = useCustomInstance<unknown>();

  return useCallback(
    (id: string) => {
      return deleteV1GoogleCalendarEventsId({
        url: `/v1/google-calendar-events/${id}`,
        method: 'DELETE',
      });
    },
    [deleteV1GoogleCalendarEventsId],
  );
};

export const useDeleteV1GoogleCalendarEventsIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteV1GoogleCalendarEventsIdHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteV1GoogleCalendarEventsIdHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteV1GoogleCalendarEventsId = useDeleteV1GoogleCalendarEventsIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteV1GoogleCalendarEventsIdHook>>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteV1GoogleCalendarEventsId(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteV1GoogleCalendarEventsIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteV1GoogleCalendarEventsIdHook>>>
>;

export type DeleteV1GoogleCalendarEventsIdMutationError = ErrorType<unknown>;

/**
 * @summary Delete a google calendar event
 */
export const useDeleteV1GoogleCalendarEventsId = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteV1GoogleCalendarEventsIdHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useDeleteV1GoogleCalendarEventsIdHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = useDeleteV1GoogleCalendarEventsIdMutationOptions(options);

  return useMutation(mutationOptions);
};
