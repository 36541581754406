import { LoadingOverlay, Paper, Text, Title } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { useGetV1BotsId } from '../../api';

export function View() {
  const { botId } = useParams();

  const { data, error, isLoading } = useGetV1BotsId(String(botId), {
    query: { enabled: Boolean(botId) },
  });

  if (isLoading || !data) {
    return <LoadingOverlay visible={true} />;
  }

  if (error) {
    return <h1>{error.message}</h1>;
  }

  return (
    <Paper p="xl" radius="ms">
      <Title order={1}>{data.body.id}</Title>
      <Text my="sm">
        <strong>Bot Name</strong>: {data.body.bot_name}
      </Text>
      <Text my="sm">
        <strong>Status</strong>: {data.body.status}
      </Text>
      <Text my="sm">
        <strong>Meeting URL</strong>: {data.body.meeting_url}
      </Text>
      <Text my="sm">
        <strong>Join At</strong>: {data.body.join_at}
      </Text>
      {data.body.metadata !== null && (
        <Text>
          <pre>{JSON.stringify(data.body.metadata, null, 2)}</pre>
        </Text>
      )}
    </Paper>
  );
}
