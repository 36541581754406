import { Route, Routes } from 'react-router-dom';
import { List } from './List';

export function CustomWords() {
  return (
    <Routes>
      <Route path="" element={<List />} />
    </Routes>
  );
}
